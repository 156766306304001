import React from 'react'
import "./Navbar.css"

export default function Navbar() {
    return (
        <div className="Navbar">
            <h3>
                Quotes 
            </h3>
            <small>curated by Etin Obaseki</small>
        </div>
    )
}
